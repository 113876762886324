@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";

@font-face {
  font-family: "Hepta-Slab-Regular";
  src: url("./fonts/hepta-slab/HeptaSlab-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Hepta-Slab-Medium";
  src: url("./fonts/hepta-slab/HeptaSlab-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Hepta-Slab-SemiBold";
  src: url("./fonts/hepta-slab/HeptaSlab-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Hepta-Slab-Bold";
  src: url("./fonts/hepta-slab/HeptaSlab-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Hepta-Slab-ExtraBold";
  src: url("./fonts/hepta-slab/HeptaSlab-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Soliden-Trial-Black";
  src: url("./fonts/soliden-trial/SolidentrialBlack-51V1a.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Publica-Sans-Roud-Bold";
  src: url("./fonts/publica-sans-round/PublicaSansRound-Bd.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Publica-Sans-Roud-Regular";
  src: url("./fonts/publica-sans-round/PublicaSansRound-Rg.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Publica-Sans-Roud-Md";
  src: url("./fonts/publica-sans-round/PublicaSansRound-Md.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* body {
  margin: 0;
  padding: 15px;
  min-width: 100%;
  min-height: 100vh;
  color: white;
  font-family: "Publica-Sans-Roud-Regular";
  /* background: url("/public/images/backgroundPage.png") no-repeat center center; */
  /* background: #180d20; */
  /* -webkit-background-size: cover; */
  /* -moz-background-size: cover; */
  /* -o-background-size: cover; */
  /* background-size: cover; */
  word-wrap: break-word;
  background: #130c1e;
}

/* FONTS CLASS */
.hepta-regular {
  font-family: "Hepta-Slab-Regular";
}

.hepta-medium {
  font-family: "Hepta-Slab-Medium";
}

.hepta-semi-bold {
  font-family: "Hepta-Slab-SemiBold";
}

.hepta-bold {
  font-family: "Hepta-Slab-Bold";
}

.hepta-extra-bold {
  font-family: "Hepta-Slab-ExtraBold";
}

.soliden-trial-black {
  font-family: "Soliden-Trial-Black";
}

.publica-sans-regular {
  font-family: "Publica-Sans-Roud-Regular";
}

.publica-sans-md {
  font-family: "Publica-Sans-Roud-Md";
}

.publica-sans-bold {
  font-family: "Publica-Sans-Roud-Bold";
}

.font-size-p {
  font-size: 36px;
}
/* FONTS CLASS */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.z-index {
  z-index: 1000;
}

.google-icon {
  width: 15px;
  height: 15px;
  margin: 5px;
}

.input-login {
  margin: 3px;
}

.button-login {
  margin: 3px;
  width: 100%;
}

.btc-logo {
  width: 200px;
  height: 200px;
}

#modal-card {
  background-color: #4a454e;
  border-radius: 10px;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.center-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
}

.right {
  display: flex;
  justify-content: right;
}

.left {
  display: flex;
  justify-content: left;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

/* USEFULL CLASSES */
.text-yellow {
  color: #f5b326;
}

.text-purple {
  color: #794ca5;
}

.text-white {
  color: #fff;
}

.horizontal-padding-2 {
  padding: 0 2em;
}

.horizontal-padding-4 {
  padding: 0 4em;
}

.horizontal-padding-6 {
  padding: 0 6em;
}

.horizontal-padding-8 {
  padding: 0 8em;
}

.horizontal-padding-10 {
  padding: 0 10em;
}

.display-block-mobile {
  display: none;
}

.display-block-desktop {
  display: block;
}

.container-padding-desktop {
  padding-left: 110px;
  padding-right: 110px;
}

/* USEFULL CLASSES */

/* VIDEO SECTION */
.video-section {
  padding-top: 180px;
  padding-bottom: 100px;
}

.video-section p {
  font-size: 0.8em;
}

.video-card {
  position: relative;
  width: 100%;
  height: 340px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.video-card > div > iframe, .video-card > div > div {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 5px 13px -2px #000;
}

.box-footer {
  position: absolute;
  top: 0px;
  right: 0px;
}

.box-links {
  position: absolute;
  bottom: 15px;
  left: calc(50% - 130px);
}

.img-footer {
  width: 89.91px;
  height: 84.13px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.icon-footer {
  width: 45.53px;
  height: 44.13px;
  margin-left: 30px;
  margin-top: 10px;
}

.copyright-footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 13px;
}

.copyright-footer > span > a:hover {
  color: #fff;
}

.yellow-button {
  background-color: #f5b326;
  padding: 14px 28px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 13px 31px -9px #000;
  font-family: "Publica-Sans-Roud-Md";
  cursor: pointer;
  color: black;
  font-size: 17px;
  min-width: 280px;
}

.yellow-button a {
  color: black;
}

.green-button {
  background-color: #25d366;
  padding: 14px 34px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 4px 12px -6px #000;
  font-family: "Publica-Sans-Roud-Md";
  cursor: pointer;
  font-size: 1rem;
}

.green-button > img {
  position: relative;
  top: 3px;
  left: -4px;
}

.gradient-background {
  background: url("/public/images/linesBackground.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* VIDEO SECTION */

/* CARDS SECTION */
.cards-section {
  margin-top: 100px;
}

.cards-section h6 {
  margin-bottom: 0 !important;
}

.cards-section p {
  font-size: 0.8em;
}

/* BUTTON SECTION */
.button-section1, .button-section2 {
  padding-top: 160px;
  padding-bottom: 160px;
  position: relative;
}

.crypto-background {
  background: url("/public/images/gradient.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.crypto-left-image {
  position: absolute;
  width: 140px;
  top: calc(50% - 30px);
  left: calc(50% - 380px);
}

.crypto-right-image {
  position: absolute;
  width: 140px;
  top: calc(50% - 70px);
  right: calc(50% - 380px);
}

.button-section2 .crypto-left-image {
  top: calc(50% - 70px);
}

.button-section2 .crypto-right-image {
  top: calc(50% - 30px);
}

/* COURSE SECTION */
.course-section {
  margin-top: 60px;
}

.course-section p {
  font-size: 1.2rem;
  font-family: "Publica-Sans-Roud-Regular";
}

.modules-list {
  padding: 20px;
  width: 100%;
}

.module-item {
  margin-bottom: 1rem;
  color: #ffffff; 
  cursor: pointer;
}

.module-title {
  font-size: 1.3rem;
  font-family: "Publica-Sans-Roud-Regular";
  padding: 1.5rem;
  border: 1px solid #633A8C;
  background-color: #191029;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 6px;
}

.module-title:hover {
  border-color: #f39c12; 
}

.module-title > span {
  font-family: "Publica-Sans-Roud-Bold";
}

.module-description {
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0 40px;
  color: #ecf0f1;
  line-height: 1.2;
}
/* COURSE SECTION */

/* WHO SECTION */
.who-section {
  margin-bottom: 80px !important;
}

.who-section p {
  font-size: 1rem;
}

.who-card {
  background-color: #2E1D44;
  padding: 80px 40px 60px;
  border-radius: 8px;
  box-shadow: 0px 5px 30px -40px #000;
  margin-top: 40px;
  position: relative;
}

.who-description {

}

.who-description > img {
  width: 260px;
}

.who-image {
  position: absolute;
  top: calc(50% - 200px);
  right: 40px;
}

.who-image > img {
  width: 400px;
}

/* FEEDBACK SECTION */
.feedback-section {
  margin: 20px 0;
}

.feedback-images-card {
  margin: 20px;
}

.feedback-videos-card {
  margin: 20px;
}

.feedback-image {
  height: 380px;
  margin: 0 15px;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.feedback-image > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.feedback-video {
  margin: 0 15px;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  border: 2px solid #633A8C;
}

.feedback-video > div {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 30px 30px;
}

.feedback-video > div > div > iframe, .feedback-video > div > div > div {
  width: 100%;
  height: 200px;
  position: relative;
}

/* ABOUT SECTION */
.about-section {
  background: url("/public/images/backgroundGradient2.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 160px 0;
  margin-top: 60px;
}

.about-section p {
  font-size: 1.3rem;
}
/* ABOUT SECTION */

/* CONTACT SECTION */
.contact-section {
  margin-bottom: 80px !important;
}

.contact-card {
  background-color: #2E1D44;
  padding: 40px 0;
  border-radius: 8px;
  box-shadow: 0px 5px 30px -40px #000;
}

.contact-card h4 {
  margin-bottom: 15px !important;
}

.contact-card p {
  font-size: 1.2rem;
  line-height: 1;
  margin-bottom: 20px;
}

.contact-card .yellow-button {
  font-size: 0.7em;
  padding: 8px 30px 12px;
}

.contact-card .yellow-button img {
  position: relative;
  top: 3px;
}

.youtube-icon {
  position: absolute;
  width: 70px;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
}

/* Nav-bar */
.header {
  position: absolute;
  margin-left: 15%;
}

.nav {
  max-width: 1280px;
  height: 130px;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 176px;
  height: 66px;
}

.nav-list {
  display: flex;
  gap: 32px;
}

.nav-list a {
  font-size: 16px;
  color: #fff;
  padding-block: 16px;
  z-index: 1;
}

.menu-hamburger {
  display: none;
  border: none;
  background: none;
  border-top: 4px solid #fff;
  cursor: pointer;
  margin-right: 20px;
}

.menu-hamburger::before,
.menu-hamburger::after {
  content: "";
  display: block;
  width: 44px;
  height: 4px;
  background: #fff;
  margin-top: 6px;
  position: relative;
  transition: 0.3s;
}

.footer-container {
  width: 1000px;
  height: 221px;
  position: relative;
}

.text-justify{
  text-align: justify !important;
}

.position-relative {
  position: relative;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .text-justify-mobile{
    text-align: justify !important;
  }

  /* Nav-bar */
  .nav {
    height: 80px;
  }

  .logo {
    width: 136px;
    height: 50px;
  }

  .menu-hamburger {
    z-index: 2;
    position: fixed;
    top: 36px;
    right: 10px;
  }

  .nav-list {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #633a8c;
    clip-path: circle(100px at 90% -15%);
    transition: 0.5s ease-out;
    padding: 6em 0 4em;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 0;
    pointer-events: all;
    z-index: 1;
  }

  .nav-list a {
    font-size: 1.5em;
    color: #fff;
    padding-block: 16px;
    z-index: 1;
  }

  .nav.active .nav-list {
    clip-path: circle(1500px at 90% -10%);
    z-index: 1;
  }

  .nav.active .menu-hamburger {
    position: fixed;
    top: 46px;
    right: 26px;
    border-top-color: transparent;
  }

  .nav.active .menu-hamburger::before {
    transform: rotate(135deg);
  }

  .nav.active .menu-hamburger::after {
    transform: rotate(-135deg);
    top: -7px;
  }

  .footer-container {
    margin-top: 60px;
    margin-bottom: 60px;
    height: 400px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .font-size-p {
    display: flex;
    justify-content: center;
    font-size: 1.8em;
  }

  .icon-footer {
    width: 60.53px;
    height: 59.13px;
  }

  .icon-footer img {
    width: 36.53px;
    height: 35.13px;
    margin-left: 0px;
    margin-top: 10px;
  }

  .img-footer {
    width: 108.9px;
    height: 104.54px;
    position: absolute;
    bottom: 90px;
    left: calc(50% - 52px);
  }

  .copyright-footer {
    position: absolute;
    width: 300px;
    left: calc(50% - 150px);
    bottom: -90px;
    font-size: 12px;
    text-align: center;
  }

  .box-footer {
    position: absolute;
    top: 60px;
    right: auto;
    align-items: center;
    padding-right: 30px;
  }

  .box-links {
    bottom: 15px;
    left: calc(50% - 117px);
  }

  .gradient-background {
    background: url("/public/images/linesBackground.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .mobile-horizontal-padding-0 {
    padding: 0 !important;
  }

  .mobile-horizontal-padding-2 {
    padding: 0 2em;
  }

  .title.is-1 {
    font-size: 2.5rem;
    white-space: nowrap;
  }

  .title.is-2 {
    font-size: 1.9rem;
  }

  .subtitle.is-2, .subtitle.is-3 {
    font-size: 1.8rem;
  }

  .subtitle.is-2.text-yellow, .subtitle.is-2.text-purple {
    font-size: 2.3rem;
    white-space: nowrap;
  }

  .video-section {
    padding: 120px 20px 100px 20px;
  }

  .video-card {
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 18rem;
    padding: 0 30px !important;
  }

  .yellow-button {
    border-radius: 10px;
    margin-top: 0px;
  }

  .button-section1 button, .button-section2 button {
    font-size: 0.9rem !important;
  }

  .button-section1 h1, .button-section2 h1 {
    margin-bottom: 20px;
  }

  .button-section1, .button-section2 {
    padding-bottom: 200px;
  }

  .crypto-left-image {
    width: 80px;
    top: initial;
    left: 0;
    bottom: 50px;
  }

  .crypto-right-image {
    width: 70px;
    top: 0;
    right: 0;
  }

  .button-section2 .crypto-left-image {
    top: 10px;
  }

  .button-section2 .crypto-right-image {
    top: initial;
    bottom: 70px;
  }

  .cards-section {
    margin-top: 80px;
    margin-left: 0;
    margin-right: 0;
  }

  .course-section {
    margin-top: 20px;
  }

  .modules-list {
    padding: 0px;
  }

  .module-title {
    font-size: 1.1rem;
  }

  .subtitle.is-6 {
    font-size: 1.6rem;
  }

  .cards-section p {
    font-size: 1.2em;
    margin-top: 8px;
  }

  .btc-card,
  .ether-card {
    padding: 60px 20px;
    border-radius: 18px;
  }

  .btc-card .yellow-button,
  .purple-button {
    padding: 20px 40px;
    font-size: 1.1em;
    margin-top: 60px;
    border-radius: 10px;
    letter-spacing: 2px;
    min-width: 260px;
  }

  .btc-coin-image,
  .ether-coin-image {
    display: none;
  }

  .display-block-mobile {
    display: block;
  }

  .display-block-desktop {
    display: none;
  }

  .about-section {
    margin-top: 80px;
    margin-bottom: 80px !important;
    padding: 80px 0;
  }

  .about-section p {
    font-size: 1.2em;
    margin-top: 20px;
  }

  .contact-card {
    padding: 60px 0;
    margin-bottom: 80px;
    border-radius: 0;
  }

  .subtitle.is-4 {
    font-size: 1.8rem;
    margin-bottom: 30px !important;
  }

  .contact-card p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }

  .green-button {
    padding: 14px 40px;
    font-size: 1em;
    min-width: 240px;
    margin-top: 20px;
    border-radius: 8px;
    letter-spacing: 1px;
    color: black;
  }

  .green-button > img {
    position: relative;
    width: 22px;
    top: 4px;
    left: 0px;
  }

  .position-relative-mobile {
    position: relative;
  }

  .container-padding-desktop {
    padding-left: 0;
    padding-right: 0;
  }

  .who-description h4 {
    text-align: center;
  }

  .who-description > img {
    width: 280px;
    position: relative;
    left: calc(50% - 140px);
    margin-bottom: 20px;
  }

  .who-description p {
    font-size: 1.1rem;
  }

  .who-card {
    border-radius: 0;
  }

  .feedback-section h1 {
    text-align: center;
  }
}
